import { NamedItem, StaffMember, Project, Country } from './organizational-structure.model';

export class Company extends NamedItem {
    Id?: string;
    name: string;
    customerNumber: string;
    street: string;
    city: string;
    houseNumber: string;
    country?: Country | string;
    zip: string;
    status: string;
    phone?: string;
    fax: string;
    companyTitle:string;
    homepage?: string;
    eMail: string;
    matchCode?: string;
    staffMembers?: StaffMember[];
    projects?: Project[];
    constructor(data) {
        super();
        Object.assign(this, data);
    }
}

import { Component, OnInit, ViewChild } from '@angular/core';

import { SpaceOneCoreDataService } from '@wissenswerft/core/data';

import { ResourceManager } from '@wissenswerft/core/resources';

import { DxFileUploaderComponent, DxPopupComponent } from 'devextreme-angular';

import { Subscription } from 'rxjs';

import { map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'document',
    templateUrl: './document.component.html',
    styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {

    @ViewChild('confirmPopup') confirmPopup: DxPopupComponent;

    @ViewChild('addDocumentPopup') addDocumentPopup: DxPopupComponent;

    @ViewChild('fileUploader') fileUploader: DxFileUploaderComponent;

    public res = ResourceManager.getResources("lib");

    private subscriptions: Subscription[] = [];

    public documents = [];

    public showLoader = false;

    private documentId: number;

    public documentsColumnsHeader = [
        {
            dataField: 'title', dataType: 'string', caption: 'Title', minWidth: 50, width: '80%', visibleIndex: 0
        },
        {
            type: "buttons",
            caption: '',
            minWidth: 70,
            dataField: '',
            visibleIndex: 2,
            buttons: [{
                icon: 'assets/images/save.svg',
                onClick: (e) => { this.downloadDocument(e.row.data.id) }
            }, {
                icon: "trash",
                onClick: (e) => { this.openConfirmDialog(e); }
            }]
        }
    ];

    constructor(private coreDataService: SpaceOneCoreDataService) { }

    ngOnInit(): void {
        this.loadDocuments();
    }

    public openConfirmDialog(event): void {
        this.documentId = event.row.data.id;
        this.confirmPopup.instance.show();
    }
    private loadDocuments = (): void => {
        this.subscriptions.push(this.coreDataService.getDocuments<[]>().subscribe(documents => {
            this.documents = documents;
        }));
    }

    public downloadDocument = (id: number): void => {
        this.showLoader = true;
        this.subscriptions.push(this.coreDataService.getDocumentFormat(id).pipe(switchMap(assets => {
            return this.coreDataService.getBlobFile(id)
                .pipe(map(blobFile => ({ assets, blobFile })));
        }))
            .subscribe(({ assets, blobFile }) => {
                const assetsMime = assets.formats.filter(format => format.name === 'source');
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(new Blob([blobFile], { type: assetsMime[0].mimeType }));
                downloadLink.setAttribute('download', assetsMime[0].filename);
                document.body.appendChild(downloadLink);
                downloadLink.click();
            }, (error) => {
                console.error(error);
            }, () => {
                this.showLoader = false;
            }));
    }

    public addDocument = () => {
        this.addDocumentPopup.instance.show();
    }

    public closeDocumentPopUp = () => {
        this.fileUploader.instance.reset();
        this.addDocumentPopup.instance.hide();
    }

    public uploadFile = (file) => {
        let xhr = this.coreDataService.importDocument(file, file.name);
        let that = this;
        xhr.addEventListener("readystatechange", function () {
            if (this.readyState === 4) {
                const response = JSON.parse(this.responseText)
                that.loadDocuments();
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.map(subscription => { subscription.unsubscribe(); });
    }

}

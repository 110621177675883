import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Comment, DeleteMode } from '../models/comment.model';
import {
  ObjectKey, PersistenceService, PersistMode,
  PersistObjectModel, GeoDsPersistenceService,
  TargetColumnValue, TargetObjectData, GeoDsCoreDataService,
  Query, QueryColumn, QueryObjectsModel
} from '@wissenswerft/core/data';
import { ProfileInfo, UserService } from '@wissenswerft/core/authentication';
import { DxButtonComponent, DxListComponent, DxTextAreaComponent } from 'devextreme-angular';
import { ResourceManager } from '@wissenswerft/core/resources';
import { Observable } from 'rxjs';

@Component({
  selector: 'comments',
  templateUrl: './comments.component.html'
})
export class CommentsComponent implements OnInit, OnChanges {
  @ViewChild('commentTextArea') dxTextArea: DxTextAreaComponent;
  @ViewChild('commentlist') dxlist: DxListComponent;
  @ViewChild('saveButton') dxbutton: DxButtonComponent;


  @Input() comments: Comment[] = [];
  @Input() itemId: number;
  @Input() deleteMode = DeleteMode.SlideItem;
  @Input() height: string;
  @Input() geoDs: boolean = false;
  public res = ResourceManager.getResources("comments");
  public flag: boolean = false;
  public comment: Comment = new Comment(null);
  private user: ProfileInfo;
  public commentText: string;

  constructor(private persistenceService: PersistenceService, private userService: UserService,
    private geoDsPersistenceService: GeoDsPersistenceService, private geoDsCoreDataService: GeoDsCoreDataService) {
  }

  ngOnInit(): void {
    this.userService.getProfileInformations().subscribe(data => {
      this.user = data;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.geoDs) {
      if (changes.itemId.currentValue != changes.itemId.previousValue) {
        this.comments = [];
        this.getComments(changes.itemId.currentValue)?.subscribe((comments) => {
          if (comments?.length > 0) {
            comments?.forEach((comment) => {
              // created format "2022-06-15T16:59:19.586+02:00"
              // InsertedDate: "2022-06-15"
              // InsertedTime: "1753-01-01T16:50:41"
              let time = comment.InsertedTime.split('T')[1];

              let createdDateTime = comment.InsertedDate + 'T' + time;
              let commentToAdd = new Comment({
                text: comment.Text, created: createdDateTime,
                id: comment.Id, author: { firstname: comment.InsertedBy }
              });
              this.comments.push(commentToAdd);
            });
          }
        })
      }
    }
  }

  public persistComment(): Observable<any> {
    const commentPersistQuery: TargetObjectData = new TargetObjectData();
    commentPersistQuery.Mode = PersistMode.Insert;
    commentPersistQuery.ObjectKey = new ObjectKey();
    commentPersistQuery.ObjectKey.ObjectType = "Comment";
    const commentColumns: TargetColumnValue[] = [
      { Name: 'Text', Value: this.commentText },
      { Name: 'ParentObjectType', Value: "AudAuditItem" },
      { Name: 'ParentId', Value: this.itemId.toString() },
    ];
    commentPersistQuery.TargetColumns = commentColumns;
    const commentQuery: PersistObjectModel = new PersistObjectModel();
    commentQuery.Object = commentPersistQuery;
    return this.geoDsPersistenceService.executePersistObjectQuery(commentQuery);
  }

  public deleteCommentGeoDs(id: string): Observable<any> {
    const persistQuery: PersistObjectModel = new PersistObjectModel();
    const targetObject: TargetObjectData = new TargetObjectData();
    targetObject.Mode = PersistMode.Delete;
    targetObject.ObjectKey = new ObjectKey();
    targetObject.ObjectKey.Id = id;
    targetObject.ObjectKey.ObjectType = "Comment";
    persistQuery.Object = targetObject;
    return this.geoDsPersistenceService.executePersistObjectQuery(persistQuery);
  }

  public getComments(id): Observable<any> {
    const commentQuery: Query = new Query();
    const commentQueryColumns: Array<QueryColumn> = [
      this.geoDsCoreDataService.createQueryColumn('Id', 'Id'),
      this.geoDsCoreDataService.createQueryColumn('Text', 'Text'),
      this.geoDsCoreDataService.createQueryColumn('SysDateInsert', 'InsertedDate'),
      this.geoDsCoreDataService.createQueryColumn('SysTimeInsert', 'InsertedTime'),
      this.geoDsCoreDataService.createQueryColumn('SysUserNameInsert', 'InsertedBy'),
    ];
    commentQuery.OPath = `ParentId='${id?.toString()}'`;
    commentQuery.ObjectType = "Comment";
    commentQuery.Columns = commentQueryColumns;

    const commentQueryDocument: QueryObjectsModel = new QueryObjectsModel();
    commentQueryDocument.ObjectQueries = [commentQuery];

    return this.geoDsCoreDataService.executeReadObjectsQuery(commentQueryDocument);
  }

  public addComment(): void {
    if (this.commentText) {
      if (!this.geoDs) {
        this.comment.text = this.commentText;
        this.persistenceService.addComment(this.comment, this.itemId).subscribe(data => {
          if (!this.comments) this.comments = [];
          data.avatar = this.userService.currentProfileAvatar;
          this.comments.splice(0, 0, data);
          this.dxlist.instance.reload();
        });
      } else {
        this.comment.text = this.commentText;
        this.persistComment().subscribe(data => {
          if (!this.comments) this.comments = [];

          this.comments.push(new Comment({
            id: data.Id,
            text: this.comment.text,
            created: new Date(),
            author: { firstname: this.user["DisplayText"] }
          }));
          this.dxlist.instance.reload();
        }, (error) => {
          console.log('persist comment error ', error);
        });
      }
      this.flag = false;
      this.dxTextArea.instance.reset();
    }
    //else {
    // this.persistenceService.updateComment(this.itemId, this.comment).subscribe(data =>{
    //   this.dxlist.instance.reload();
    // });
    // this.dxTextArea.instance.reset();
    // this.flag = false;
    // this.dxbutton.icon ='add';
    // }
  }

  public deleteComment(event): void {
    if (!this.geoDs) {
      this.persistenceService.deleteComment(event.itemData.id, this.itemId).subscribe();
    } else {
      this.deleteCommentGeoDs(event.itemData.id).subscribe();
    }
  }

  public updateComment(event): void {
    // this.flag = true;
    // this.dxbutton.icon = 'save'
    // this.dxTextArea.value = event.itemData.text;
    // if (this.dxTextArea.value != event.itemData.text) {
    //   this.comment.text = this.dxTextArea.value;
    // }
  }

  public calculateDuration(commentDate): number {
    const difference = Date.now() - Date.parse(commentDate);
    const hours = Math.floor(difference / (60 * 60 * 1000));
    return hours;
  }
}
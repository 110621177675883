import { NamedItem } from "../models/organizational-structure.model";

export class NamedItemViewModel {
    private namedItem: NamedItem;

    get ident() { return this.namedItem.ident; }

    get label() { return this.namedItem.label; }

    set ident(data) { this.namedItem.ident = data; }

    set label(data) { this.namedItem.label = data; }
    
    constructor(data: NamedItem) {
        this.namedItem = data;
    }
}

export const VISIBLE_PROPERTIES = [
    'ident', 'label'
];


export enum HomeMode {
  home = 'home',
  company = 'company'
}

export const HomeOptions = [{
  text: "DashBoard",
  path: 'dashboard',
  heightRatio: 2,
  widthRatio: 4,
},
{
  text: "Catalogue of risks",
  path: 'risks',
  heightRatio: 2,
  widthRatio: 4
},
{
  text: "Catalogue of measures",
  path: 'measures',
  heightRatio: 2,
  widthRatio: 4
},
{
  text: "Export",
  path: '',
  heightRatio: 2,
  widthRatio: 4
}
];


export class ClickOrigin {
  public id: string | number;
  public source: Source;
  public value: boolean;

  constructor(data) {
    Object.assign(this, data);
  }
}

export enum Source {
  RiskDetail = 'riskDetail',
  MeasureDetail = 'measureDetail'
}

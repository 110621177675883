import { MeasureRecommendation } from './measure-recommendation.model';
import { RiskIssue } from './risk-issue.model';
import { ProfileInfo } from '@wissenswerft/core/authentication';
import { Meta } from './customer-space.model';
import { Company } from "./company.model";
import { Time } from '@angular/common';

export interface NavigationMenu {
  text: string;
  component: string;
  path?: string;
  icon: string;
  isExpanded?: boolean;
  items?: NavigationMenu[];
  onClick?(): unknown;
}

export class CatalogLibraryBase {
  id: number;
  ident: string;
}

export class NamedItem extends CatalogLibraryBase {
  title?: string;
  label?: string;
}

export class Standard extends NamedItem {
  description: string;
  measures: MeasureRecommendation[];
  riskIssues: RiskIssue[];
}

export class Role extends CatalogLibraryBase {
  name: string;
}

export class SubModule extends CatalogLibraryBase {
  ident: string;
  label: string;
  module: number;
  constructor(data) {
    super();
    Object.assign(this, data);
  }
}

export class Module extends CatalogLibraryBase {
  ident: string;
  label: string;
  submodules: SubModule[];

  constructor(data) {
    super();
    Object.assign(this, data);
    if (data?.submodules) {
      this.submodules = [];
      for (let index = 0; index < data.submodules.length; index++) {
        const property = data.submodules[index];
        this.submodules.push(property);
      }
    }
  }
}

export class LegalBasis extends NamedItem {
  description: string;
  link: string;
}

export class ResponsiblePlan {
  plannedDays: number;
  remainingDays: number;
  responsible: ProfileInfo;
  measure: MeasureRecommendation;

  constructor(data) {
    Object.assign(this, data);
  }
}

export class RiskAssesmentMeasure {
  riskIssue: RiskIssue;
  measure: MeasureRecommendation;
  constructor(data) {
    Object.assign(this, data);
  }
}

export class Tag extends NamedItem {
  constructor() {
    super();
  }
}

export class Product extends NamedItem {
  constructor() {
    super();
  }
}

export class Country extends NamedItem {
  constructor() {
    super();
  }
}

export class MeasureDefinition extends NamedItem {
  constructor() {
    super();
  }
}

export class MeasureBenefit extends NamedItem {
  constructor() {
    super();
  }
}

export class MeasureProgress extends NamedItem {
  progress: string;
  nextSteps: string;
  decision: string;
  opportunities: string;
  riskAndChance: string;
  date: Date;
  maturityLevel: string;
  progressPercentage: number;
  constructor() {
    super();
  }
}

export class TypeOfMeasure extends NamedItem {
  constructor() {
    super();
  }
}

export class DamageKind extends NamedItem {
  constructor() {
    super();
  }
}

export class StaffMember extends NamedItem {
  firstname: string;
  lastname: string;
  eMail: string;
  responsible: ProfileInfo;
  role: Role;
  constructor(data) {
    super();
    Object.assign(this, data);
  }
}

export class Collaborator extends CatalogLibraryBase {
  name: number;
  timePlanned: number;
  timeExecuted: number;
  constructor(data) {
    super();
    Object.assign(this, data);
  }
}

export class Event extends NamedItem {
  constructor() {
    super();
  }
}

export class Project extends NamedItem {
  subProjects: SubProject[];
  constructor(data) {
    super();
    Object.assign(this, data);
  }
}

export class SubProject extends NamedItem {
  event: Event;
  highlights: string;
  lowlights: string;
  responsible: ProfileInfo;
  responsibleSupport: ProfileInfo;
  implementationStatus: string;
  meta?: Meta;
  constructor(data) {
    super();
    Object.assign(this, data);
  }
}

export class Chance extends NamedItem {
  constructor() {
    super();
  }
}

export class Decision extends NamedItem {
  constructor() {
    super();
  }
}

export class Activity extends NamedItem {
  constructor() {
    super();
  }
}

export class Resource extends NamedItem {
  staffMembers: StaffMember[];
  constructor() {
    super();
  }
}

export class Audit {
  Id?: string;
  AuditDate: Date;
  Score?: number;
  MaxScore?: number;
  Percentage?: number;
  Designation: string;
  AllAuditItems?: AuditItem[];
  Library: Library;
  Company: Company;
}

export enum AuditItemTypes {
  Question = 'Question',
  Category = 'Category',
  Response = 'Response'
}
export class AuditItem {
  Id: string;
  Score: number;
  MaxScore: number;
  Percentage: number;
  SeqNr: number;
  SortNr?: number;
  Type: AuditItemTypes;
  Title: string;
  Designation: string;
  SubAuditItems: AuditItem[];
  Category: BibCategory;
  Question: BibQuestion;
  Response: BibResponse;
  //Measures: Measure[];
}
export class Library {
  Id: string;
  Designation: string;
  Kind: string;
  Categories: BibCategory[];
  constructor(data?) {
    if (data) {
      Object.assign(this, data);
      if (data?.Categories) {
        this.Categories = [];
        data.Categories.forEach((category) => {
          this.Categories.push(new BibCategory(category));
        });
      }
    }
  }
}

export class BibCategory {
  Id: string;
  Designation: string;
  Percentage?: number;
  Score?: number;
  SortNr?: number;
  Kind?: string;
  MaxScore?: number;
  Questions: BibQuestion[];
  constructor(data?) {
    if (data) {
      Object.assign(this, data);
      if (data?.Questions) {
        this.Questions = [];
        data.Questions.forEach((question) => {
          this.Questions.push(new BibQuestion(question));
        });
      }
    }
  }
}

export class BibQuestion {
  ParentId: string;
  Id: string;
  Designation: string;
  SeqNr: number;
  Title: string;
  Description: string;
  Type: string;
  SortNr?: number;
  Percentage?: number;
  MaxScore?: number;
  Factor?: number;
  Score?: number;
  Responses: BibResponse[];
  InsertedDate: Date;
  UpdatedDate: Date;
  SysTimeInsert: Time;
  SysTimeUpdate: Time;
  SysUserNameInsert: string;
  SysUserNameUpdate: string;
  constructor(data?) {
    if (data) {
      Object.assign(this, data);
      if (data?.Responses) {
        this.Responses = [];
        data.Responses.forEach((response) => {
          this.Responses.push(new BibResponse(response));
        });
      }
    }
  }
}

export class BibResponse {
  ParentId: string;
  Id: string;
  SeqNr: number;
  Designation: string;
  SortNr?: number;
  Measures?: BibMeasure[];
  Score: number;
  constructor(data?) {
    if (data) {
      Object.assign(this, data);
      if (data?.Measures) {
        this.Measures = [];
        data.Measures.forEach((measure) => {
          this.Measures.push(new BibMeasure(measure));
        });
      }
    }
  }
}

export class BibMeasure {
  Id: string;
  SeqNr: number;
  Title: string;
  SortNr?: number;
  Goal: string;
  Designation?: string;
  constructor(data?) {
    Object.assign(this, data);
  }
}

// export class Measure extends MeasureRecommendation {
//   Id: string;
//   BibMeasure: BibMeasure;
//   constructor(data?) {
//     super(data)
//     Object.assign(this, data);
//   }
// }


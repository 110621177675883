import { ChangeDetectorRef, Injectable } from "@angular/core";
import { ProfileInfo } from "@wissenswerft/core/authentication";
import { SpaceOneCoreDataService, TypeKey } from "@wissenswerft/core/data";
import { ResourceManager } from "@wissenswerft/core/resources";
import { forkJoin, Observable, of, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { DataDefinition } from "../models/customer-space.model";
import { DataDefinitionViewModel } from "../view-models/customer-space.view-model";


@Injectable()
export class SharedDataService {

    public definitionsVM: DataDefinitionViewModel[] = [];

    private gridDataSubject = new Subject<any>();

    public updateGridData$ = this.gridDataSubject.asObservable();

    private _responsibles: Observable<ProfileInfo[]>;

    public responsibleById: {};


    constructor(private dataService: SpaceOneCoreDataService) { }

    public getDefinitionAndData<T>(typeKey: TypeKey): Observable<[DataDefinition, T]> {
        if (this.definitionsVM[typeKey]) {
            return forkJoin([of(this.definitionsVM[typeKey]), this.dataService.getItemsByName<T>(typeKey)]);
        }
        else {
            return forkJoin([this.dataService.getDefinitonByTypeKey<DataDefinition>(typeKey), this.dataService.getItemsByName<T>(typeKey)]);
        }
    }

    public updateGridData(data: any) {
        this.gridDataSubject.next(data);
    }

    public createPersistObject(data, multilingualProperties, listProperties) {
        const query = data;
        multilingualProperties.forEach(prop => {
            if (query.hasOwnProperty(prop.key)) {
                query[prop.key] = { "de": query[prop.key], 'en': query[prop.key] };
            }
        });
        listProperties.forEach(prop => {
            if (query.hasOwnProperty(prop.key)) {
                if (!query[prop.key]?.length && query[prop.key]?.length !== 0 && query[prop.key] !== undefined) {
                    query[prop.key] = [query[prop.key]];
                }
            }
        });
        return query;
    }

    public get responsibles(): Observable<ProfileInfo[]> {
        if (!this._responsibles) {
            this._responsibles = this.dataService.getAllAccounts<ProfileInfo[]>().pipe(map(users => {
                this.responsibleById = {};
                users.forEach((user => {
                    user = new ProfileInfo(user);
                    this.responsibleById[user.id] = user;
                }));
                return users;
            }));
        }
        return this._responsibles;
    }

    public set responsibles(data) {
        this._responsibles = data;
    }

    public getDataType(dataType) {
        switch (dataType) {
            case 'TEXT':
                return 'string';
            case 'NUMBER':
                return 'number';
            case 'LOCALDATE':
                return 'date';
            case 'BOOLEAN':
                return 'boolean';
            default:
                return 'string';
        }
    }

    
}
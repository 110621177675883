import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { DataDefinitionViewModel, NamedItem, NamedItemViewModel, SharedDataService, VISIBLE_PROPERTIES } from '@wissenswerft/organizational-structure';

import { PersistenceService, TypeKey } from '@wissenswerft/core/data';

import { DxPopupComponent } from 'devextreme-angular';

import { Column } from 'devextreme/ui/data_grid';

import { Observable, Subscription } from 'rxjs';

import { GridComponent } from '@wissenswerft/ww-library';

import { ToastType } from '@wissenswerft/ww-library';

import { WindowLayoutComponent } from '@wissenswerft/ww-library';
import { ResourceManager } from '@wissenswerft/core/resources';

@Component({
    selector: 'named-item',
    templateUrl: './named-item.component.html',
    styleUrls: ['./named-item.component.scss']
})
export class NamedItemComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('namedItemGrid') namedItemGrid: GridComponent;

    @ViewChild('createTagPopup') createTagPopup: DxPopupComponent;

    @ViewChild('windowLayout') windowLayout: WindowLayoutComponent;

    @Input('typeKey') typeKey: TypeKey;
    @Input() isCreateButtonFloating: boolean = true;
    @Input() showIdent = true;
    @Input('id') id: string;
    @Input() creationTitle: string;
    @Input() showCloseButton = true;

    @Input() windowLabelMode: string;

    @Input() editingMode: string = 'row';

    @Output() showNotification = new EventEmitter();

    public res = ResourceManager.getResources("lib");

    public gridData: NamedItemViewModel[];

    public title: string;

    public pluralTitle: string;

    public columnsHeader: Column[] = [];

    public namedItem: NamedItem;

    private subscriptions: Subscription[] = [];

    constructor(private sharedDataService: SharedDataService, private persistenceService: PersistenceService) { }


    ngOnInit(): void {
        this.namedItem = new NamedItem();
        this.prepareNamedItemData(this.typeKey);
    }

    public prepareNamedItemData(typeKey: TypeKey): void {
        if (this.sharedDataService.definitionsVM[TypeKey.tag]) {
            this.title = this.creationTitle ? this.creationTitle : this.res('Lib-NamedItem-Create') + this.sharedDataService.definitionsVM[TypeKey.tag].name;
        }
        this.subscriptions.push(this.sharedDataService.getDefinitionAndData<NamedItem[]>(typeKey).subscribe(data => {
            this.gridData = [];
            this.columnsHeader = [];
            const definitions = data[0];
            this.sharedDataService.definitionsVM[typeKey] = definitions;
            this.title = this.creationTitle ? this.creationTitle : this.res('Lib-NamedItem-Create') + ' ' + definitions.name;
            this.pluralTitle = definitions.namePlural;
            const namedItems = data[1];
            const definitionVM = new DataDefinitionViewModel(definitions, VISIBLE_PROPERTIES);
            this.sharedDataService.definitionsVM[typeKey].definitionVM = definitionVM;
            const properties = definitionVM.properties;
            for (const key in properties) {

                if (properties[key]) {
                    const property = properties[key];
                    this.columnsHeader.push({
                        dataField: property.key,
                        caption: property.label,
                        visible: (property.key === 'ident' && this.showIdent === false) ? false : property?.visible,
                        dataType: this.sharedDataService.getDataType(property.type)
                    });
                }
            }
            namedItems.forEach((namedItem: NamedItem) => {
                const namedItemViewModel = new NamedItemViewModel(namedItem);
                this.gridData.push(namedItemViewModel);
            });
        }));

        this.subscriptions.push(this.sharedDataService.updateGridData$.subscribe((tag: NamedItemViewModel) => {
            this.gridData.push(tag);
        }));
    }

    ngAfterViewInit(): void {
        this.subscriptions.push(this.windowLayout.onSaving.subscribe(() => {
            this.createNamedItem();
        }));
    }

    public removeRow(event): void {
        const namedItemId = event.data.id || event.data.namedItem.id;
        this.subscriptions.push(this.persistenceService.addObjectForDelete(namedItemId).subscribe(() => {
            this.showNotification.emit({ message: this.res('Lib-Notification-Empty'), type: ToastType.INFO });
        }, error => {
            this.showNotification.emit({ message: error, type: ToastType.ERROR });
        }));
    }

    public updateRow(event): void {
        if (event.newData?.label !== '' && event.newData?.ident !== '') {
            const namedItemId = event.oldData.id || event.oldData.namedItem.id;
            const query: NamedItem = new NamedItem();
            query.label = event.newData.label ? event.newData.label : event.oldData.label;
            query.ident = event.newData.ident ? event.newData.ident.replace(/\s/g, '') : event.oldData.ident.replace(/\s/g, '')
            const multilingualProperties = this.sharedDataService.definitionsVM[this.typeKey].definitionVM.multilingualProperties;
            const listProperties = this.sharedDataService.definitionsVM[this.typeKey].definitionVM.listProperties;
            const object = this.sharedDataService.createPersistObject(query, multilingualProperties, listProperties);
            this.subscriptions.push(this.persistenceService.addObjectForUpdate(namedItemId, object).subscribe(() => {
                this.showNotification.emit({ message: this.res('Lib-Notification-Success'), type: ToastType.SUCCESS });
            }, error => {
                this.showNotification.emit({ message: error, type: ToastType.ERROR });
            }));
        } else {
            event.cancel = true;
            this.showNotification.emit({ message: this.res("Lib-Notification-Empty"), type: ToastType.INFO });
        }
    }

    private createNamedItem(): void {
        this.persistObject().subscribe((data: NamedItemViewModel) => {
            this.showNotification.emit({ message: this.res('Lib-Notification-Success'), type: ToastType.SUCCESS });
            this.sharedDataService.updateGridData(data);
        }, error => {
            this.showNotification.emit({ message: error, type: ToastType.ERROR });
        });
        this.namedItem.ident = null;
        this.namedItem.label = null;
    }

    private persistObject(): Observable<any> {
        const multilingualProperties = this.sharedDataService.definitionsVM[this.typeKey].definitionVM.multilingualProperties;
        const listProperties = this.sharedDataService.definitionsVM[this.typeKey].definitionVM.listProperties;
        const query = this.sharedDataService.createPersistObject(this.namedItem, multilingualProperties, listProperties);
        return this.persistenceService.addObjectForInsert(this.typeKey, query);
    }

    public openTagDialog(): void {
        this.createTagPopup.instance.show();
    }

    public onClosePopup(): void {
        this.createTagPopup.instance.hide();
    }

    ngOnDestroy(): void {
        this.subscriptions.map(subscription => { subscription.unsubscribe(); });
    }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxButtonModule, DxFileUploaderModule, DxLoadPanelModule, DxPopupModule, DxSpeedDialActionModule } from 'devextreme-angular';
import { WwLibraryModule } from '@wissenswerft/ww-library';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NamedItemComponent } from './shared/namedItem/named-item.component';
import { DocumentComponent } from './shared/document/document.component';

@NgModule({
  declarations: [
    NamedItemComponent,
    DocumentComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    WwLibraryModule,
    DxPopupModule,
    DxSpeedDialActionModule,
    DxFileUploaderModule,
    DxLoadPanelModule,
    DxButtonModule
  ],
  exports: [
    NamedItemComponent
  ]
})
export class OrganizationalStructureModule {}

export enum SortOrder {
    ASCENDANT = 'asc',
    DESCENDANT = 'desc'
}

export enum FieldArea {
    COLUMN = 'column',
    DATA = 'data',
    FILTER = 'filter',
    ROW = 'row'
}